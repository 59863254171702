@import 'styles/includes.scss';

.CTACampaignCard {
    $root: &;
    position: relative;
    padding-top: 0;
    padding-bottom: 4rem;
    background-color: $colorBlack800;

    @include media(ml) {
        margin-bottom: 0;
        padding: 0rem 0 8rem 0;
    }

    &::after {
        @include media(ml) {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: calc(50% - 70px);
            left: 0;
            transform: translateY(-50%);
            border-right: 155px solid transparent;
            border-bottom: 205px solid $colorWhiteBackground;
            z-index: 0;
        }
    }

    &--WhiteBackground {
        background-color: $colorWhiteBackground;

        @include media(ml) {
            padding: 8rem 0 8rem 0;
        }

        &::after {
            display: none;
        }
    }

    &__Background {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        top: 50%;
        background-color: $colorWhiteBackground;
        z-index: 0;
    }

    &__ImageContainer {
        margin-left: auto;
        margin-right: auto;
        padding: 0;
        position: relative;
        z-index: 1;
        transform: translateY(50px);
        opacity: 0;
        transition: transform 0.4s ease-out, opacity 0.5s ease-out;
        transition-delay: $transitionDelayFast;

        @include media(ml) {
            padding: 0 $spacingXl * 3;
        }

        @include media(l) {
            padding: 0 $spacingXl * 4;
        }

        @include media(xl) {
            max-width: (map-get($maxwidths, xl));
            padding: 0;
        }

        &--isVisible {
            opacity: 1;
            transform: translateY(0px);
        }
    }

    &__Container {
        position: relative;
    }

    &__ImageWrapper {
        height: 420px;
        padding-bottom: 0;
        position: relative;

        @include media(m) {
            height: 0;
            padding-bottom: calc((700 / 1260) * 100%);
        }

        @include media(l) {
            height: 0;
            padding-bottom: calc((600 / 1260) * 100%);
        }
    }

    &__Image {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__TextContainer {
        position: relative;
        padding: 0 1.6rem;
        bottom: 6.5rem;
        width: 100%;
        filter: drop-shadow(0px 0px 3px lightgray);
        margin-bottom: -6rem;

        @include media(ml) {
            position: absolute;
            left: 6rem;
            max-width: 558px;
            padding: 0;
            min-height: 300px;
            filter: none;
            margin-bottom: 0;
        }

        @include media(l) {
            max-width: 618px;
            min-height: 315px;
        }
    }

    &__TextWrapper {
        background-color: white;
        padding: 32px 25px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        word-break: break-word;
        hyphens: auto;
        clip-path: polygon(
            0 0,
            0 0,
            90% 0,
            100% 0,
            100% 80%,
            80% 100%,
            0 100%,
            0% 90%,
            0% 10%
        );

        @include media(m) {
            min-height: 200px;
            padding: 44px 32px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            clip-path: polygon(
                0 0,
                0 0,
                90% 0,
                100% 0,
                100% 70%,
                86% 100%,
                0 100%,
                0% 90%,
                0% 10%
            );
        }

        @include media(l) {
            padding: 52px 52px;
        }
    }

    &__Preamble {
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 14px;
        letter-spacing: 2.6px;
        text-transform: uppercase;

        @include media(m) {
            font-size: 1.4rem;
        }
    }

    &__Title {
        font-size: 2.6rem;
        font-weight: 700;
        letter-spacing: 0.4px;
        line-height: 1.32;

        @include media(m) {
            font-size: 3.5rem;
        }
    }

    &__Text {
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 1.65;
    }

    &__LinkContainer {
        margin-top: 14px;

        @include media(m) {
        }
    }
}
